import { useContext } from 'react';
import { hasUnmatchedFunds, historicalPortfolioHasDuplicatedInvestments } from '../../../review/helpers';
import { MultiPortfolioReviewContext } from '../../common/MultiPortfolioReviewContext';

export const useFooter = ({ isHistorical }: { isHistorical: boolean }) => {
  const {
    actions: { onBackButtonClick, completeUpload },
    data: {
      parsedResults,
      savePortfoliosMutationState: { isIdle },
    },
  } = useContext(MultiPortfolioReviewContext);
  const anyPortfolioHasUnmatchedFunds = parsedResults.some((parsedResult) =>
    hasUnmatchedFunds(parsedResult.parsedPortfolio),
  );
  const anyPortfolioHasDuplicateInvestments =
    isHistorical &&
    parsedResults.some((parsedResult) => historicalPortfolioHasDuplicatedInvestments(parsedResult.parsedPortfolio));

  return {
    onCancel: onBackButtonClick,
    onContinue: completeUpload,
    disabled: !isIdle || anyPortfolioHasUnmatchedFunds || anyPortfolioHasDuplicateInvestments,
    primaryTooltip: anyPortfolioHasUnmatchedFunds
      ? 'Some portfolios contain investments that are not mapped to investments in the Data Library. Please ensure all investments are mapped in order to continue.'
      : anyPortfolioHasDuplicateInvestments
        ? 'Some portfolios contain duplicate allocations.  Please ensure all investments have no duplicate allocations in order to continue.'
        : undefined,
  };
};
